import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import { blogList } from "../../data/blogs";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/south-park-a-hidden-gem-in-san-diego-real-estate-market"
  );

  return (
    <LayoutWrapper
      title="A Hidden Gem in San Diego’s Real Estate Market"
      desc="Find out why the South Park neighborhood in San Diego is a hidden gem in the real estate market! It has historic homes, a convenient location & much more. Read to learn more!
      "
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              A Hidden Gem in San Diego’s Real Estate Market
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Jan 25, 2024</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_South Park A Hidden Gem In San Diego's Real Estate Market.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <a
                  href="https://selbysellssd.com/san-diego-communities/south-park-homes-for-sale"
                  target="_Blank"
                  className="text-blue-500"
                >
                  San Diego’s South Park Neighborhood
                </a>{" "}
                — nestled on the southeast corner of Balboa Park and running to the convergence of
                highways 15 and 94 — is a desirable area that offers the appeal of a small community
                amidst a thriving city atmosphere. Initially a suburb of San Diego, this century-old
                neighborhood has survived decades of urban expansion without losing its unique
                heritage and charm.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you’re seeking the perfect blend of location and history, you’ll want to
                familiarize yourself with the attractive homes and vibrant commercial districts in
                this neighborhood. What makes the area so unique? And exactly why is South Park a
                hidden gem in San Diego’s real estate market?
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Convenient Location
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                It’s difficult to overstate just how conveniently located South Park is. For
                starters, you’ll enjoy easy access to nearby Balboa Park, which is complete with
                green spaces, museums, and the Spanish Village Art Center, featuring dozens of local
                artists selling paintings, pottery, and jewelry.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Just a few minutes’ drive from the bustling Gaslamp Quarter, downtown San Diego, the
                fantastic eateries of Little Italy, and, of course, the waterfront, South Park puts
                you near the city’s heart. You can reach the San Diego International Airport in just
                15 minutes.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                South Park delivers if you’re looking for a quiet neighborhood that offers proximity
                to the best of urban life. Tree-lined streets invite foot traffic and cyclists
                alike, and you’ll love the local boutiques and art galleries dotting the area. And
                if you want more, you must hop on the nearby freeway or take advantage of ample
                public transit to traverse the city.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Historic Homes With Plenty of Character
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                California has never been a state that fears reinvention, with the old often making
                way for the new. While South Park doesn’t exist in a bubble where time stands still,
                the neighborhood has taken great pains to retain the historic beauty that makes it
                so unique.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As a result, you’ll find plenty of options for classic home styles, including
                stunning Victorian and Craftsman properties that are anything but cookie-cutter. You
                can also find newer construction options, including townhouses, condos, and
                single-family homes that ensure every buyer finds just what they’re looking for.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Additionally, efforts to revitalize older properties have resulted in lofts and
                commercial spaces designed to save and adapt existing buildings for new uses.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                A Friendly and Inclusive Community
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Playgrounds, parks, and green spaces in and around the South Park neighborhood offer
                endless opportunities to get out and enjoy the nearly 150 sunny days per year, on
                average.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                This area experiences low crime rates, and residents tend to be involved in the
                community. Neighborhood watch programs help to ensure safety, and community events
                like block parties and festivals foster inclusivity.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The diverse community encourages engagement. Meet neighbors while walking your dog,
                or join the annual South Park Walkabout, featuring food, art exhibits, live music,
                and the chance to meet other residents and local business owners.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Find Your Ideal Home in South Park Today
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Moving to South Park means joining a welcoming community that feels like home. With
                the right realty team, you can find the ideal home for your needs in this hidden gem
                of a neighborhood.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Are you interested in touring homes in{" "}
                <a
                  href="https://selbysellssd.com/san-diego-communities/south-park-homes-for-sale"
                  target="_Blank"
                  className="text-blue-500"
                >
                  San Diego’s South Park neighborhood
                </a>
                ? <ContactSlideoutLink text="Contact The Selby Team" /> to get started on finding
                your new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
